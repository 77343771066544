/*
* ink colors (with variations only for black and white)
*/
.pusatBantuan__head {
  background: #1D66DD;
  color: #ffffff; }

.pusatBantuan__heroSection {
  padding: calc(0.75 * 1rem); }
  .pusatBantuan__heroSection__title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    margin: calc(0.75 * 1rem) 0; }
  .pusatBantuan__heroSection__subtitle {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 2.29;
    padding: 0 calc(0.75 * 1rem); }
  .pusatBantuan__heroSection__imgContainer {
    margin-bottom: 40px;
    justify-self: center; }
    .pusatBantuan__heroSection__imgContainer img {
      width: 175px !important;
      height: 241.6px !important; }
  .pusatBantuan__heroSection__cta {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media (min-width: 768px) {
      .pusatBantuan__heroSection__cta {
        width: 50%;
        margin: auto; } }
    @media (min-width: 992px) {
      .pusatBantuan__heroSection__cta {
        display: grid;
        grid-gap: 2%;
        grid-template-columns: 50% 50%;
        width: 100%;
        margin-top: calc(1.25 * 1rem); } }
    .pusatBantuan__heroSection__cta a {
      border-radius: calc(2 * 1rem);
      padding: calc(0.75 * 1rem);
      background: transparent;
      border: 1px solid #ffffff;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: calc(0.75 * 1rem);
      text-align: center;
      text-decoration: none; }
    .pusatBantuan__heroSection__cta i {
      margin-right: calc(2 * 1rem); }
  .pusatBantuan__heroSection .active__btn {
    background: #ffffff;
    color: #1D66DD; }
  @media (min-width: 992px) {
    .pusatBantuan__heroSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: calc(0.75 * 1rem); }
      .pusatBantuan__heroSection__texts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%; }
      .pusatBantuan__heroSection__imgContainer {
        margin-bottom: 0; }
        .pusatBantuan__heroSection__imgContainer img {
          width: 294px !important;
          height: 404px !important; }
      .pusatBantuan__heroSection__title {
        text-align: left;
        font-size: 32px; }
      .pusatBantuan__heroSection__subtitle {
        text-align: left;
        padding: 0;
        font-size: 20px; } }

.pusatBantuan__body {
  padding: 48px 0; }
  .pusatBantuan__body__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    color: #1D66DD;
    margin: calc(0.75 * 1rem) 0; }
  .pusatBantuan__body__subtitle {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
    margin: calc(0.75 * 1rem) 0; }
    @media (min-width: 768px) {
      .pusatBantuan__body__subtitle {
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
        margin: calc(1.25 * 1rem) 0; } }

.pusatBantuan__faq__title {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .pusatBantuan__faq__title span:last-child {
    margin-left: calc(3.25 * 1rem); }

.pusatBantuan__faq__body .card {
  border: none;
  box-shadow: none !important; }

.pusatBantuan__faq__body .card-body {
  color: #333333; }

.pusatBantuan__faq__body_email span {
  font-weight: 500; }

.pusatBantuan__faq__body_email_box {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  padding: 24px;
  border-radius: 8px;
  border: solid 1px #d7dfe9;
  background-color: #fff;
  width: fit-content; }
  .pusatBantuan__faq__body_email_box span {
    margin-top: 15px; }
  .pusatBantuan__faq__body_email_box div :first-child {
    margin-left: 25px; }
  .pusatBantuan__faq__body_email_box div :last-child {
    margin-top: 10px; }
  .pusatBantuan__faq__body_email_box div span:first-child {
    color: #1D66DD; }

.pusatBantuan table {
  width: 100%; }

.pusatBantuan table tr td {
  padding: calc(0.5 * 1rem);
  border: 1px solid #333333; }
